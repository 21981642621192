import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { UserService } from "src/services/UserService";
import { getFirstPathSegment } from "src/services/Utility";
import { Product, User, UserOptions } from "src/types";
import { MockAccountAdminProduct } from "./mocks/MockAccountAdminProduct";
import { MockMigrationProduct } from "./mocks/MockMigrationProduct";
import { TRITECH_ROLE } from "./constants";

export const fetchData = async (dispatch) => {
    try {
        const userService = UserService.getInstance();

        // Fetch self and products using Auth0 JWT
        const self: User = await userService.getSelf();
        dispatch(GlobalStateActions.setSelf(self));

        // Using user id from self, get user products
        const products = await userService.getUserProducts(self.id);

        dispatch(GlobalStateActions.Products.setProducts(products));

        // Filter out duplicate products and keep the one with the latest tax year
        const productMap = new Map<string, Product>();
        products?.forEach((product: Product) => {
            const productName: string = product.productName
                .toLowerCase()
                .replace(/\s+/g, "-");
            const existingProductInMap: Product | undefined =
                productMap.get(productName);
            if (
                !existingProductInMap ||
                existingProductInMap.taxYear < product.taxYear
            ) {
                productMap.set(productName, product);
            }
        });

        // Set the product based on the first path segment
        let firstPathSegment = getFirstPathSegment();
        if (firstPathSegment === "allocator-app") {
            firstPathSegment = "allocator";
        }
        let product = productMap.get(firstPathSegment);

        // To Do: Remove these temporary changes
        if (firstPathSegment === "account-admin")
            product = MockAccountAdminProduct as Product;

        if (product) {
            dispatch(GlobalStateActions.setProduct(product));
        } else {
            console.error(
                `Product not found for path segment: ${firstPathSegment}`
            );
        }

        const processedProducts = Array.from([...productMap.values()]);

        // Check to see if the user has access to Account Admin
        if (
            self.roles?.includes(TRITECH_ROLE.TritechAdmin) ||
            self.roles?.includes(TRITECH_ROLE.ClientAccountAdmin)
        ) processedProducts.push(MockAccountAdminProduct as Product);
        else processedProducts.push(MockMigrationProduct as Product);

        // Sort products by name
        processedProducts.sort((a, b) =>
            a.productName.localeCompare(b.productName)
        );

        dispatch(
            GlobalStateActions.Products.setProcessedProducts(processedProducts)
        );

        // Fetch user options
        const userOptions: UserOptions = await userService.getUserOptions();
        if(userOptions) {
            dispatch(GlobalStateActions.UserOptions.setUserOptions(userOptions));
        }

    } catch (error) {
        console.error("Error fetching data:", error);
    }
};
