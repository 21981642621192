import React, { useEffect, useState, useRef } from "react";
import "./ReturnTreeAccordion.scss";
import { getModuleId } from "src/services/Utility";
import {
    returnTreeQuery,
    returnCompaniesTreeQuery,
} from "src/services/GQLQueries";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import Loader from "../Loader/Loader";
import { useSearchParams } from "react-router-dom";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import FolderNodeDialog from "../FolderNodeDialog/FolderNodeDialog";
import PaymentRequestExport from "../PaymentRequestExport/PaymentRequestExport";
import { Company, FixMeLater, Product, State, Tree } from "src/types";
import { GraphqlService } from "src/services";
import FolderNode from "../FolderNode/FolderNode";
import { useAppDispatch, useAppSelector } from "src/hooks";
import { filterForFolderNodes } from "src/utils/TreeUtils";
import { Modal } from "../../uikit";
import GenericReturn from "../GenericReturn/GenericReturn";

interface ReturnTreeAccordionProps {
    handleSnackbar: (message: string, severity: string) => void;
    show?: boolean;
}

const ReturnTreeAccordion: React.FC<ReturnTreeAccordionProps> = ({
    show,
    handleSnackbar,
}) => {
    const [searchParams] = useSearchParams();

    const dispatch = useAppDispatch();
    const [expanded, setExpanded] = useState<Set<string>>(new Set());
    const [error, setError] = useState<FixMeLater>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [folderNodeDialogOpen, setFolderNodeDialogOpen] = useState(false);
    const [folderNodeForDialog, setFolderNodeForDialog] =
        useState<FixMeLater>(null);

    const [folderNodeGenericReturnOpen, setFolderNodeGenericReturnOpen] = useState(false);
    const [folderNodeForGenericReturn, setFolderNodeForGenericReturn] =
        useState<FixMeLater>(null);

    const product: Product | undefined = useAppSelector(
        (state) => state?.Product?.value
    );

    const companies = useAppSelector((state) => state?.Companies?.value);

    if (!product) return null; // We need to show a message when this happens

    const dropdownStateView: boolean = useAppSelector((state) => {
        return state?.UserOptions?.value?.productPreferences[
            product?.productName
        ]?.dropdownStateView;
    });

    const company: Company | null = useAppSelector(
        (state) => state[product?.productName]?.value?.company
    );
    const state: State | null = useAppSelector(
        (state) => state[product?.productName]?.value?.state
    );

    const municipalState: string = useAppSelector(
        (state) => state?.Municipal?.value?.selectedState?.abbrev
    );
    const selectedQuarter = useAppSelector(
        (state: FixMeLater) =>
            state?.[product?.productName]?.value?.selectedQuarter
    );

    const tree: Tree = useAppSelector(
        (state) => state[product?.productName]?.value?.tree
    );

    const graphqlService = GraphqlService.getInstance();
    const [loadedFromUrl, setLoadedFromUrl] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            if (
                (company || state) &&
                product &&
                (product.productId !== 2 ||
                    (product.productId === 2 && municipalState))
            ) {
                setIsLoading(true);
                try {
                    let loadedTree;
                    if (dropdownStateView) {
                        if (state) {
                            const compTreeInput = {
                                folderId: state?.id,
                                productId: product?.productId,
                                taxYearId: product?.taxYear,
                            };

                            const { treeCompanies } =
                                await graphqlService.fetchData<{
                                    treeCompanies: Tree;
                                }>(returnCompaniesTreeQuery, { compTreeInput });
                            loadedTree = treeCompanies;
                            dispatch(
                                GlobalStateActions[
                                    product?.productName
                                ].setTree(treeCompanies)
                            );
                        }
                    } else {
                        const treeInput = {
                            companyId: company?.id,
                            productId: product?.productId,
                            taxYearId: product?.taxYear,
                            moduleId: getModuleId(
                                product,
                                company,
                                municipalState
                            ),
                        };

                        const { tree } = await graphqlService.fetchData<{
                            tree: Tree;
                        }>(returnTreeQuery, { treeInput });
                        loadedTree = tree;

                        dispatch(
                            GlobalStateActions[product?.productName].setTree(
                                tree
                            )
                        );
                    }

                    if (!loadedFromUrl) {
                        // Check if folderNodeId and returnNodeId params exist in the URL
                        const folderNodeId = searchParams.get("folderNodeId");
                        const returnNodeId = searchParams.get("returnNodeId");
                        const companyId = searchParams.get("companyId");

                        if (dropdownStateView) {
                            const companyParam = searchParams.get("companyId");

                            if (companyParam) {
                                let selectedCompany = companies?.find(
                                    (company: Company) =>
                                        company.id === parseInt(companyParam)
                                );
                                // If company exists, set it
                                if (selectedCompany)
                                    dispatch(
                                        GlobalStateActions[
                                            product?.productName
                                        ].setCompany(selectedCompany)
                                    );
                            }
                        }

                        const folderNodeIdToUse = dropdownStateView
                            ? companyId
                            : folderNodeId;

                        const folderNode = loadedTree.folderNodes.find(
                            (folderNode) => folderNode?.id === folderNodeIdToUse
                        );
                        const returnNode = folderNode?.returnNodes?.find(
                            (returnNode) => returnNode?.id === returnNodeId
                        );

                        if (folderNode) {
                            dispatch(
                                GlobalStateActions[
                                    product?.productName
                                ].setFolderNode(folderNode)
                            );

                            setExpanded(
                                new Set([...expanded, folderNode.id as string])
                            );
                        }

                        if (returnNode)
                            dispatch(
                                GlobalStateActions[
                                    product?.productName
                                ].setReturnNode(returnNode)
                            );

                        setLoadedFromUrl(true);
                    }

                    setError(null);
                } catch (error) {
                    setError(
                        dropdownStateView
                            ? "There was an error fetching the return tree for the specified year, state. Please reach out to support."
                            : "There was an error fetching the return tree for the specified year, company. Please reach out to support."
                    );
                    console.error("Error fetching return tree:", error);
                } finally {
                    setIsLoading(false);
                }
            }
        };
        fetchData();
    }, [company, product, municipalState, state, dropdownStateView]);

    const onFolderNodeClick = (folderNodeId: string) => {
        const newExpanded = new Set(expanded);
        if (newExpanded.has(folderNodeId)) newExpanded.delete(folderNodeId);
        else newExpanded.add(folderNodeId);
        setExpanded(newExpanded);
    };

    if (error) return <ErrorMessage error={error.toString()} />;

    if (!error && isLoading) return <Loader color="white" />;

    if (!error && !isLoading && !company && !state) {
        return (
            <div
                className="return-tree-accordion-container"
                style={{ visibility: `${show ? "visible" : "hidden"}` }}
            >
                <div className="company-not-selected">
                    {dropdownStateView
                        ? "No state selected."
                        : "No company selected."}
                </div>
            </div>
        );
    }
    const getFolderNodeElements = (tree: Tree): React.JSX.Element[] => {
        if (product.productName === "Municipal") {
            return tree.folderNodes
                .filter(filterForFolderNodes)
                .filter(
                    (folderNode) =>
                        folderNode?.attributes?.displayName.includes(
                            selectedQuarter
                        ) ||
                        folderNode?.attributes?.displayName ===
                            "Company Information" ||
                        folderNode?.attributes?.displayName ===
                            "Summary Schedules"
                )
                .map((folderNode) => {
                    if (
                        folderNode.attributes?.displayName ===
                        "Company Information"
                    ) {
                        const filteredReturnNodes =
                            folderNode.returnNodes.filter((returnNode) => {
                                if (
                                    returnNode.id === "1" ||
                                    returnNode.id == "4" || 
                                    returnNode.id == "818"
                                ) {
                                    return true;
                                }
                                if (municipalState === "KY") {
                                    const matchWords =
                                        extractMatchWordsFromQtrString(
                                            selectedQuarter
                                        );
                                    if (matchWords !== null) {
                                        return matchWords.some((word) =>
                                            returnNode.displayName.includes(
                                                word.toString()
                                            )
                                        );
                                    }
                                }
                                return true;
                            });
                        const modifiedFolderNode = {
                            ...folderNode,
                            returnNodes: filteredReturnNodes,
                        };
                        folderNode = modifiedFolderNode;
                    }

                    if (
                        folderNode?.attributes?.displayName ===
                        "Summary Schedules"
                    ) {
                        const filteredReturnNodes = folderNode?.returnNodes
                            .filter((returnNode) => {
                                if (
                                    returnNode.id === "101" ||
                                    returnNode.id === "2757" ||
                                    (selectedQuarter.includes("Qtr") &&
                                        returnNode.id === "2892") ||
                                    (selectedQuarter.includes(
                                        "Reconciliation"
                                    ) &&
                                        returnNode.id === "2893")
                                ) {
                                    return true;
                                }

                                if (municipalState === "KY") {
                                    const matchWords =
                                        extractMatchWordsFromQtrString(
                                            selectedQuarter
                                        );
                                    if (matchWords !== null) {
                                        return matchWords.some((word) =>
                                            returnNode.displayName.includes(
                                                word.toString()
                                            )
                                        );
                                    }
                                }

                                return (
                                    (selectedQuarter.includes("Qtr") &&
                                        returnNode.id === "2892") ||
                                    (selectedQuarter.includes(
                                        "Reconciliation"
                                    ) &&
                                        returnNode.id === "2893")
                                );
                            })
                            .map((returnNode) => ({
                                ...returnNode,
                                displayName:
                                    returnNode.displayName.includes(
                                        selectedQuarter
                                    ) ||
                                    returnNode.id === "101" ||
                                    (selectedQuarter === "Reconciliation" &&
                                        returnNode.displayName.includes("Rec"))
                                        ? returnNode.displayName
                                        : `${returnNode.displayName} ${
                                              selectedQuarter !==
                                              "Reconciliation"
                                                  ? `${selectedQuarter}`
                                                  : "Rec"
                                          }`,
                            }));

                        const modifiedFolderNode = {
                            ...folderNode,
                            returnNodes: filteredReturnNodes,
                        };
                        folderNode = modifiedFolderNode;
                    }

                    return (
                        <FolderNode
                            key={folderNode?.id}
                            folderNode={folderNode}
                            expanded={expanded.has(folderNode?.id as string)}
                            onFolderNodeClick={onFolderNodeClick}
                            onFolderNodeDialogOpen={(folderNode) => {
                                setFolderNodeForDialog(folderNode);
                                setFolderNodeDialogOpen(true);
                            }}
                            onGenericReturnDialogOpen={(folderNode) => {
                                setFolderNodeForGenericReturn(folderNode);
                                setFolderNodeGenericReturnOpen(product?.productName === "FormsPlus");
                            }}
                        />
                    );
                });
        }

        return tree?.folderNodes
            ?.filter(filterForFolderNodes)
            ?.map((folderNode) => {
                return (
                    // <FileUploadAccordion
                    //     key={folderNode?.id}
                    //
                    // />
                    <FolderNode
                        key={folderNode?.id}
                        folderNode={folderNode}
                        expanded={expanded.has(folderNode?.id as string)}
                        onFolderNodeClick={onFolderNodeClick}
                        onFolderNodeDialogOpen={(folderNode) => {
                            setFolderNodeForDialog(folderNode);
                            setFolderNodeDialogOpen(true);
                        }}
                        onGenericReturnDialogOpen={(folderNode) => {
                            setFolderNodeForGenericReturn(folderNode);
                            setFolderNodeGenericReturnOpen(true);
                        }}
                    />
                );
            });
    };

    const extractMatchWordsFromQtrString = (qtrString: string): string[] => {
        const match = qtrString.match(/Qtr\s(\d+)/);
        if (match && match[1]) {
            return [match[1]];
        } else if (qtrString === "Reconciliation") {
            return ["Qtrs", "Rec"];
        } else {
            return [""];
        }
    };

    if ((!company && !state) || !tree) return null;

    const folderNodes = getFolderNodeElements(tree);

    return (
        <>
            <div
                className="return-tree-accordion-container"
                style={{ visibility: `${show ? "visible" : "hidden"}` }}
            >
                {dropdownStateView ? (
                    folderNodes?.length <= 0 ? (
                        <p>No returns active for the selected State</p>
                    ) : (
                        folderNodes
                    )
                ) : (
                    folderNodes
                )}
            </div>
            <FolderNodeDialog
                open={folderNodeDialogOpen}
                onClose={() => setFolderNodeDialogOpen(false)}
                folderNode={folderNodeForDialog}
            />
            <Modal
                open={folderNodeGenericReturnOpen}
                onClose={() => setFolderNodeGenericReturnOpen(false)}
                title={`Create Generic Return for ${folderNodeForGenericReturn?.attributes.displayName}`}
            >
                <GenericReturn
                    genericReturnCreateKey={{
                        companyId: dropdownStateView
                            ? folderNodeForGenericReturn?.id?.toString()
                            : company?.id?.toString(),
                        folderId: folderNodeForGenericReturn?.id,
                        moduleId: getModuleId(
                            product,
                            company
                                ? dropdownStateView
                                    ? folderNodeForGenericReturn
                                    : company
                                : {},
                            municipalState
                        ),
                        productId: product?.productId.toString(),
                        taxYearId: product?.taxYear.toString(),
                    }}
                    onClose={() => setFolderNodeGenericReturnOpen(false)}
                    handleSnackbar={handleSnackbar}
                />
            </Modal>
            <PaymentRequestExport handleSnackbar={handleSnackbar} />
        </>
    );
};

export default ReturnTreeAccordion;
