import React, { FC, useState } from "react";
import { DropEvent, ErrorCode, FileRejection, useDropzone } from "react-dropzone";
import { Upload } from "@mui/icons-material";
import Loader from "src/components/Loader/Loader";
import colors from "src/styles/colors.scss";
import { FixMeLater } from "src/types";
import "./GenericReturn.scss";
import { ACCEPTED_FILE_TYPES, getErrorMessageByCode } from "./GenericReturn.util";
import { useAppDispatch, useAppSelector } from "src/hooks";
import {
    Autocomplete,
    TextField,
} from "@mui/material";
import { HttpError } from "src/utils/HttpError";
import { StyledFlexContainer } from "../../products/AccountAdmin/AccountAdmin.styled";
import { USStateAbbreviations } from "../../constants/States";
import { dueDateRegex, zipRegex } from "../../constants/Regex";
import { GenericReturnService } from "../../services/GenericReturnService";
import { GenericReturnCreationResult } from "../../types/GenericReturn.types";
import { getModuleId } from "../../services/Utility";
import GQLService from "../../services/GQLService";
import { returnTreeQuery } from "../../services/GQLQueries";
import GlobalStateActions from "../../redux/slices/GlobalStateActions";
import { useAuth0 } from "@auth0/auth0-react";

interface GenericReturnCreateKey {
    companyId: string;
    moduleId: string;
    productId: string;
    taxYearId: string;
    folderId: string;
}

interface GenericReturnProps {
    genericReturnCreateKey: GenericReturnCreateKey;
    onClose: (e: DropEvent | Event) => void;
    handleSnackbar: (message: string, severity: string) => void;
}

const GenericReturn: FC<GenericReturnProps> = ({genericReturnCreateKey, onClose, handleSnackbar}) => {
    const dispatch = useAppDispatch();
    const genericReturnService = GenericReturnService.getInstance();
    const { getAccessTokenSilently } = useAuth0();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const product: FixMeLater = useAppSelector((state) => state?.Product.value);
    const company = useAppSelector((state) => state?.[product?.productName]?.value?.company);

    const [validationErrors, setValidationErrors] = useState({
        naic: "",
        naicGroup: "",
        federalId: "",
        addressZip: "",
    });

    const initialFormData = {
        formName: "",
        dueDate: "",
        payee: "",
        addressOption: "",
        address1: "",
        address2: "",
        addressCity: "",
        addressState: "",
        addressZip: "",
    };

    const addressOptions = [
        "Mailing Address",
        "Physical Address"
    ]
    const [formData, setFormData] = useState(initialFormData);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        disabled: isLoading,
        multiple: false,
        accept: ACCEPTED_FILE_TYPES,
        onDrop: (acceptedFiles: File[], rejectedFiles: FileRejection[], event: DropEvent | Event) => {
            if (rejectedFiles?.length) {
                handleRejectedFileCode(rejectedFiles[0]?.errors[0]?.code, event ?? new Event("genericReturn"));
            } else {
                handleUpload(acceptedFiles, event ?? new Event("genericReturn"));
            }
        },
    });

    const acceptStyle = {
        border: `1px dashed ${colors.allocatorSelectedContainerBorderColor}`,
        backgroundColor: colors.allocatorComponentBackgroundColor,
    };

    const handleRejectedFileCode = (errorCode: ErrorCode | string, event: DropEvent | Event) => {
        onClose(event);
        handleSnackbar(getErrorMessageByCode(errorCode), "error");
    };

    const handleUpload = async (files: File[], event: DropEvent | Event) => {
        setIsLoading(true);
        const errorMessage : string[] = []
        if (formData.formName === "") {
            errorMessage.push("Form name cannot be empty");
        }
        if (!dueDateRegex.test(formData.dueDate)) {
            errorMessage.push("Due Date should be MM/DD/YYYY format.");
        }
        if (!zipRegex.test(formData.addressZip)) {
            errorMessage.push("Zip Code should be a 5-digit number.");
        }
        if (errorMessage.length > 0) {
            handleSnackbar(errorMessage.toString(), "error");
            setIsLoading(false);
            return;
        }

        const fileFormData = new FormData();
        fileFormData.append("file", files[0], files[0].name)
        const attachmentMetadata = new Blob(
            [
                JSON.stringify(
                    genericReturnCreateKey
                ),
            ],
            { type: "application/json" },
        );

        const formMetadata = new Blob(
            [
                JSON.stringify(
                    formData
                ),
            ],
            { type: "application/json" },
        );
        fileFormData.append("scheduleInput", attachmentMetadata);
        fileFormData.append("formData", formMetadata)
        try {
            const response: GenericReturnCreationResult = await genericReturnService.createGenericReturn(fileFormData);
            if (response.errorMessage.includes("successfully")) {
                handleSnackbar(response.errorMessage, "success");
                dispatch(GlobalStateActions.setToogleFetch(true));
            } else {
                handleSnackbar(response.errorMessage, "error");
            }
        } catch (error: FixMeLater) {
            console.log("eeee" + error)
            if (error instanceof HttpError) {
                handleSnackbar(JSON.parse(error?.body)?.message, "error");
            } else {
                handleSnackbar("Error creating generic return", "error");
            }
        } finally {
            setIsLoading(false);
            onClose(event);
        }

        const treeInput = {
            companyId: company.id,
            productId: product?.productId,
            taxYearId: product?.taxYear,
            moduleId: getModuleId(
                product,
                company,
                null
            ),
        };
        const accessToken = await getAccessTokenSilently();

        const data: FixMeLater = await GQLService.fetchGraphQLData(
            returnTreeQuery,
            { treeInput },
            accessToken
        );

        dispatch(
            GlobalStateActions[product?.productName].setTree(data.tree)
        );

    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        if (name === "dueDate" && !dueDateRegex.test(value)) {
            setValidationErrors({
                ...validationErrors,
                naicGroup: "Due Date should be MM/DD/YYYY format.",
            });
        } else if (name === "addressZip" && !zipRegex.test(value)) {
            setValidationErrors({
                ...validationErrors,
                addressZip: "Zip Code should be a 5-digit number.",
            });
        } else {
            setValidationErrors({
                ...validationErrors,
                [name]: "",
            });
        }
    };

    return (
        <div style={{width: '1000px'}}>
            <StyledFlexContainer >
                <TextField sx={{ marginY: 1 }}
                    variant="outlined"
                    required
                    fullWidth
                    id="formName"
                    label="Form Name"
                    name="formName"
                    value={formData.formName}
                    onChange={handleInputChange}
                />
                <TextField sx={{ marginY: 1 }}
                    variant="outlined"
                    fullWidth
                    required
                    id="dueDate"
                    label="Due Date"
                    name="dueDate"
                    value={formData.dueDate}
                    onChange={handleInputChange}
                />
                <TextField sx={{ marginY: 1 }}
                    variant="outlined"
                    fullWidth
                    required
                    id="payee"
                    label="Payee/Addressee"
                    name="payee"
                    value={formData.payee}
                    onChange={handleInputChange}
                />
            </StyledFlexContainer>

            <Autocomplete sx={{ marginY: 1 }}
                          id="addressOption"
                          options={addressOptions}
                          value={formData.addressOption}
                          onChange={(
                              event: any,
                              newValue: string | null
                          ) => {
                              handleInputChange({
                                  target: {
                                      name: "addressOption",
                                      value: newValue ?? ""
                                  }
                              } as React.ChangeEvent<HTMLInputElement>);
                          }}
                          renderInput={(params) => (
                              <TextField
                                  {...params}
                                  label="Address Option"
                                  variant="outlined"
                                  fullWidth
                              />
                          )}
                          fullWidth
                          autoHighlight
                />

            <TextField sx={{ marginY: 1 }}
                variant="outlined"
                required
                fullWidth
                id="address1"
                label="Address1"
                name="address1"
                value={formData.address1}
                onChange={handleInputChange}
            />
            <TextField sx={{ marginY: 1 }}
                variant="outlined"
                fullWidth
                id="address2"
                label="Address2"
                name="address2"
                value={formData.address2}
                onChange={handleInputChange}
            />

            <StyledFlexContainer>
                <TextField sx={{ marginY: 1 }}
                    variant="outlined"
                    required
                    fullWidth
                    id="addressCity"
                    label="City"
                    name="addressCity"
                    value={formData.addressCity}
                    onChange={handleInputChange}
                />
                <Autocomplete sx={{ marginY: 1 }}
                    id="addressState"
                    options={USStateAbbreviations}
                    value={formData.addressState}
                    onChange={(
                        event: any,
                        newValue: string | null,
                    ) => {
                        handleInputChange({
                            target: {
                                name: "addressState",
                                value: newValue ?? "",
                            },
                        } as React.ChangeEvent<HTMLInputElement>);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="State"
                            variant="outlined"
                            fullWidth
                        />
                    )}
                    fullWidth
                    autoHighlight
                />

                <TextField sx={{ marginY: 1 }}
                    variant="outlined"
                    required
                    fullWidth
                    id="addressZip"
                    label="Zip Code"
                    name="addressZip"
                    value={formData.addressZip}
                    onChange={handleInputChange}
                    error={!!validationErrors.addressZip}
                    helperText={validationErrors.addressZip}
                />
            </StyledFlexContainer>
            <div
                {...getRootProps({
                    className: "import-sbp-data-dropzone",
                    style: isDragActive ? acceptStyle: {},
                })}
            >
                {isLoading ? (
                    <Loader color={colors.primaryColor}/>
                ) : (
                    <div className="import-sbp-data-content">
                        <input {...getInputProps()} />
                        <Upload
                            fontSize="medium"
                            style={{ color: colors.primaryColor }}
                        />
                        {isDragActive ? (
                            <p>Drop a document here</p>
                        ) : (
                            <p>Click or drag PDF (.pdf) file</p>
                        )}
                    </div>
                )}
            </div>
         </div>
    );
};

export default GenericReturn;
