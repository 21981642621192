import { Tooltip } from "@mui/material";
import React, { CSSProperties, FC, useEffect, useState } from "react";
import {
    NumField,
    TextField,
    CheckField as CheckFieldType,
    ReturnPage,
    CheckFieldTemplate,
} from "src/types";
import DynamicColors from "src/utils/DynamicColors";
import "./CheckField.scss";
import { useDebugMode } from "src/hooks/debugMode";
import { useDebugModeDisplayHidden } from "../../../hooks/debugModeDisplayHidden";

interface CheckFieldProps {
    scale: number;
    templateField: CheckFieldTemplate;
    value: {
        value: string | number | boolean;
        isOverride: boolean;
        documentField: NumField | TextField | CheckFieldType;
    };
    updateReturnDocument: (
        fieldType: string,
        documentNode: NumField | TextField | CheckFieldType,
        page: ReturnPage,
        overrideValue: string | number | boolean
    ) => void;
    isLocked?: boolean;
    returnPage: ReturnPage;
    id: string;
}

const CheckField: FC<CheckFieldProps> = ({
    scale,
    templateField,
    value,
    updateReturnDocument,
    isLocked,
    returnPage,
    id,
}) => {
    const { position, size } = templateField?.screenBox || {};
    const {
        isEnabled,
        overrideState,
        tabIndex,
        checkFormatType,
        toolTip,
        fieldType,
        // id,
    } = templateField;

    const pageTabIndex = returnPage.attributes.displayOrder * 100 + tabIndex;

    const [isChecked, setIsChecked] = useState<boolean>(Boolean(value?.value));

    const debugMode = useDebugMode();
    const debugModeDisplayHidden = useDebugModeDisplayHidden();

    useEffect(() => {
        // Update inputValue when value.value changes
        setIsChecked(Boolean(value?.value));
    }, [value]);

    const [isFocused, setIsFocused] = useState(false);

    const fontSize = Math.min(size?.width! * scale, size?.height! * scale);

    const handleCheckboxFocus = () => {
        setIsFocused(true);
    };

    const handleCheckboxBlur = () => {
        setIsFocused(false);
    };

    const handleCheckboxChange = () => {
        if (isLocked) return;
        if (!isChecked != Boolean(value.value)) {
            updateReturnDocument(
                "checkFields",
                value?.documentField,
                returnPage,
                !isChecked
            );
        }
        setIsChecked((prev) => !prev);
    };

    const dynamicTextFieldColor = DynamicColors.calculateDynamicTextFieldColor(
        isEnabled,
        overrideState,
        value?.isOverride,
        debugModeDisplayHidden
    );
    const dynamicBackgroundColor =
        DynamicColors.calculateDynamicBackgroundColor(
            isEnabled,
            overrideState,
            debugModeDisplayHidden
        );
    // Dynamic styles
    const dynamicStyles: CSSProperties = {
        backgroundColor: dynamicBackgroundColor,
        color: isChecked ? dynamicTextFieldColor : "transparent",
        left: `${position?.left! * scale}px`,
        top: `${position?.top! * scale}px`,
        height: `${size?.height! * scale}px`,
        width: `${size?.width! * scale}px`,
        boxShadow: isFocused ? "0 0 0 2px rgba(0, 0, 255)" : "none",
    };

    const dynamicStylesDarkenOverlay: CSSProperties = {
        backgroundColor: isChecked ? dynamicTextFieldColor : "transparent",
    };

    const dynamicStylesXOverlay: CSSProperties = {
        fontSize: `${fontSize}px`,
    };

    return (
        <Tooltip title={toolTip} placement="top">
            <div className={"check-field-container"} style={dynamicStyles}>
                {debugMode ? (
                    <input
                        style={{
                            backgroundColor: dynamicBackgroundColor,
                            width: `${15 + fontSize * scale}px`,
                            color: dynamicTextFieldColor,
                            fontSize: `${fontSize}px`,
                        }}
                        value={"C" + templateField.id}
                        readOnly={true}
                        tabIndex={pageTabIndex}
                        disabled={!isEnabled}
                    />
                ) : (
                    <>
                        <input
                            className={`checkbox-input ${
                                isLocked ? "checkbox-input--locked" : ""
                            }`}
                            type="checkbox"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                            readOnly={isLocked}
                            id={id}
                            data-testid={id}
                            tabIndex={pageTabIndex}
                            onFocus={handleCheckboxFocus}
                            onBlur={handleCheckboxBlur}
                            disabled={!isEnabled}
                            // style={{ cursor: isLocked ? "not-allowed" : "" }}
                        />
                        {checkFormatType === "DARKEN" && (
                            <label
                                className="darken-overlay"
                                style={dynamicStylesDarkenOverlay}
                            ></label>
                        )}
                        {checkFormatType === "X" && (
                            <label
                                className="x-overlay"
                                style={dynamicStylesXOverlay}
                            >
                                X
                            </label>
                        )}
                        {checkFormatType === "CHECKMARK" && (
                            <label
                                className="x-overlay"
                                style={dynamicStylesXOverlay}
                            >
                                ✓
                            </label>
                        )}
                    </>
                )}
            </div>
        </Tooltip>
    );
};

export default CheckField;
