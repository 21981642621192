import { useAuth0 } from "@auth0/auth0-react";
import { FilterAltOutlined as FilterAltOutlinedIcon } from "@mui/icons-material";
import LaunchIcon from "@mui/icons-material/Launch";
import { IconButton, PopoverPosition, SelectChangeEvent } from "@mui/material";
import {
    GridCallbackDetails,
    GridCellParams,
    GridCellSelectionModel,
    GridColDef,
    GridEventListener,
    GridRowId,
    GridSortItem,
    MuiEvent,
    useGridApiRef,
} from "@mui/x-data-grid-premium";
import { Dayjs } from "dayjs";
import React, {
    BaseSyntheticEvent,
    useCallback,
    useEffect,
    useState,
} from "react";
import ErrorMessage from "src/components/ErrorMessage/ErrorMessage";
import Loader from "src/components/Loader/Loader";
import {
    addressScheduleId,
    loginSchedule,
    scheduleSBPIdLifePage1,
    scheduleSBPIdLifePage2,
} from "src/constants/Schedules";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { GraphqlService, ScheduleService } from "src/services";
import {
    returnTreeQuery,
    scheduleTemplateQuery,
} from "src/services/GQLQueries";
import GQLService from "src/services/GQLService";
import { SBPJurisdictionService } from "src/services/SBPJurisdictionService";
import {
    getModuleId,
    getQuarterId,
    replaceUrlParam,
} from "src/services/Utility";
import {
    CellType,
    ColumnTemplateDTO,
    Company,
    DataGridRow,
    FixMeLater,
    FolderNode,
    Jurisdiction,
    Product,
    ReturnNode,
    RowDTO,
    ScheduleRows,
    TabProperties,
    ToolbarItemName,
    Tree,
} from "src/types";
import CustomSnackbar from "../CustomSnackbar/CustomSnackbar";
import Toolbar from "../Toolbar/Toolbar";
import ConfirmationDialog from "./components/ConfirmationDialog";
import { CustomColumnMenu } from "./components/CustomColumnMenu";
import { customNoData } from "./components/CustomNoData";
import { getCustomToolbar } from "./components/CustomToolbar";
import DataTableContextMenu, {
    ContextMenuItem,
} from "./components/DataTableContextMenu";
import SelectAllRowsButton from "./components/SelectAllRowsButton";
import "./DataTable.scss";
import { StyledDataGrid } from "./DataTable.styled";
import {
    handleCopy,
    handleCopyWithHeaders,
    handlePaste,
} from "./util/CopyAndPaste.util";
import {
    checkDisplayOrderInRowDTOs,
    createDataGridColumns,
    createDataGridRows,
    customColumnHeader,
    findUpdatedColumnNames,
    isCellEditable,
    isSBPSchedule,
    renderCell,
} from "./util/DataTable.util";
import { openLogin } from "./util/Login.util";
import { getToolbarIcons } from "./util/ToolbarIcons.util";

export type DataTableProps = {
    documentKey: string;
    isActive: boolean;
    company: Company;
    product: Product;
    folderNode: FolderNode;
    returnNode: ReturnNode;
    initJurisdiction?: number;
    state?: string;
    quarter?: string;
    tabLoadingMap: Map<string, boolean>;
    setTabLoadingMap: React.Dispatch<
        React.SetStateAction<Map<string, boolean>>
    >;
};

const DataTable: React.FC<DataTableProps> = ({
    documentKey,
    isActive,
    company,
    product,
    folderNode,
    returnNode,
    initJurisdiction = 1,
    state,
    quarter,
    tabLoadingMap,
    setTabLoadingMap,
}) => {
    // Service Instances

    const scheduleService = ScheduleService.getInstance();
    const sBPJurisdictionService = SBPJurisdictionService.getInstance();
    const graphqlService = GraphqlService.getInstance();

    const { getAccessTokenSilently } = useAuth0();

    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [confirmationMessage, setConfirmationMessage] = useState<string>("");
    const [dialogTitle, setDialogTitle] = useState("");

    // State

    const [columns, setColumns] = useState<GridColDef[]>([]);
    const [rows, setRows] = useState<DataGridRow[]>([]);
    const [rowDTOs, setRowDTOs] = useState<RowDTO[]>([]);
    // prettier-ignore
    const [columnTemplateDTOs, setColumnTemplateDTOs] = useState<ColumnTemplateDTO[]>([]);
    // prettier-ignore
    const [headerHeight, setHeaderHeight] = useState<number | undefined>(undefined);

    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState<string>("error");
    const [snackbarDuration, setSnackbarDuration] = useState<number>(3000);
    // prettier-ignore
    const [snackbarMessage, setSnackbarMessage] = useState<string | string[]>("");

    const [sortModel, setSortModel] = useState<GridSortItem[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<FixMeLater>(null);

    const [columnMap, setColumnMap] = useState<Map<string, number>>(new Map());
    const [rowMap, setRowMap] = useState<Map<number, number>>(new Map());
    // prettier-ignore
    const [updatedRows, setUpdatedRows] = useState<Set<number>>(new Set<number>());

    // prettier-ignore
    const [selectedJurisdiction, setSelectedJurisdiction] = useState<number>(initJurisdiction);
    // prettier-ignore
    const [jurisdictions, setJurisdictions] = useState<Map<number, Jurisdiction>>(new Map());

    const [selectedPage, setSelectedPage] = useState<number>(0);
    const [pendingChanges, setPendingChanges] = useState<boolean>(false);

    const [rowSelected, setRowSelected] = useState<readonly GridRowId[]>([]);
    // prettier-ignore
    const [cellSelected, setCellSelected] = useState<GridCellSelectionModel>({});

    // prettier-ignore
    const [contextMenuPosition, setContextMenuPosition] = useState<PopoverPosition>({ top: 0, left: 0 });
    const [isPasteAvailable, setIsPasteAvailable] = useState(true);

    const [selectedItemName, setSelectedItemName] =
        useState<ToolbarItemName | null>(null);

    // Variables

    const apiRef = useGridApiRef();

    const isMenuOpen =
        contextMenuPosition.top !== 0 && contextMenuPosition.left !== 0;

    const showMenu =
        rowSelected.length > 0 || Object.keys(cellSelected).length > 0;

    // Redux

    const dispatch = useAppDispatch();

    const toogleFetch: boolean = useAppSelector(
        (state) => state?.Tabs?.toogleFetch
    );

    const splitScreen = useAppSelector((state) => state?.Tabs?.splitScreen);

    const tabsProperties = useAppSelector(
        (state) => state?.Tabs
    )?.tabsProperties;

    const dropdownStateView: boolean = useAppSelector((state) => {
        return state?.UserOptions?.value?.productPreferences[
            product.productName
        ]?.dropdownStateView;
    });
    // useEffect hooks

    useEffect(() => {
        if (apiRef.current && Object.keys(apiRef.current).length) {
            const cols = apiRef.current.getAllColumns();
            const maxHeight = cols.reduce((max, column) => {
                const calcHeight =
                    (column.field.length * 10) / column.computedWidth;
                return Math.max(max, calcHeight);
            }, 0);
            if (maxHeight <= 1) {
                setHeaderHeight(undefined);
            } else {
                setHeaderHeight(120);
            }
        }
    }, [apiRef, columns]);

    useEffect(() => {
        if (tabsProperties[documentKey]) {
            const updatedTabProperties: TabProperties = {
                ...tabsProperties[documentKey],
                isActive: isActive,
            };
            dispatch(GlobalStateActions.setTab(updatedTabProperties));
        }

        if (isActive) {
            replaceUrlParam("year", product?.taxYear);
            replaceUrlParam("companyId", company?.id);
            replaceUrlParam("folderNodeId", folderNode?.id);
            replaceUrlParam("returnNodeId", returnNode?.id);

            if (product?.productName === "Municipal") {
                replaceUrlParam("selectedState", state);
                replaceUrlParam("selectedQuarter", quarter);
            }
            dispatch(GlobalStateActions.setToogleFetch(true));
        }
    }, [isActive]);

    useEffect(() => {
        const fillDataGrid = async () => {
            try {
                setIsLoading(true);
                let updatedColumns = createDataGridColumns(
                    columnTemplateDTOs,
                    rowDTOs,
                    renderCell,
                    handleRowDropDownChange,
                    handleSimpleDropDownChange,
                    handleCheckboxChange,
                    handleDateChange,
                    customColumnHeader,
                    apiRef,
                    columnMap,
                    sortModel,
                    setSortModel
                );

                if (returnNode.id == loginSchedule) {
                    updatedColumns =
                        createColumnsWithGoButtonForLoginSchedule(
                            updatedColumns
                        );
                }
                const updatedRows = createDataGridRows(
                    columnTemplateDTOs,
                    rowDTOs,
                    returnNode
                );
                setColumns(updatedColumns);
                setRows(updatedRows);
                setError(null);
            } catch (error: FixMeLater) {
                console.error("Error loading the schedule:", error);
                setError("There has been an error loading the schedule.");
            } finally {
                setIsLoading(false);
            }
        };
        if (columnTemplateDTOs?.length) {
            fillDataGrid();
        }
    }, [columnTemplateDTOs, rowDTOs, sortModel]);

    useEffect(() => {
        const loadSBPData = async () => {
            if (isSBPSchedule(returnNode)) {
                let jurisdictionMap = jurisdictions;
                if (!jurisdictions.size) {
                    const response: Jurisdiction[] =
                        await sBPJurisdictionService.getSBPJurisdictionsByYear(
                            product.taxYear
                        );
                    response.push({
                        id: 99,
                        longName: "All States",
                        abbrev: "All",
                    });
                    jurisdictionMap = response.reduce(
                        (map, jurisdiction: Jurisdiction) => {
                            map.set(jurisdiction.id, jurisdiction);
                            return map;
                        },
                        new Map<number, Jurisdiction>()
                    );
                    setJurisdictions(jurisdictionMap);
                }
                if (!jurisdictionMap.get(selectedJurisdiction)) {
                    setSelectedJurisdiction(
                        jurisdictionMap?.entries()?.next()?.value?.[0] ||
                            initJurisdiction
                    );
                    setSelectedPage(0);
                } else {
                    fetchData();
                }
            } else {
                setSelectedJurisdiction(0);
            }
        };

        const fetchData = async () => {
            try {
                const currentScheduleId =
                    returnNode.id === scheduleSBPIdLifePage1 &&
                    selectedPage === 1
                        ? scheduleSBPIdLifePage2
                        : returnNode.id;
                const scheduleInput = {
                    companyId: dropdownStateView
                        ? Number(folderNode?.id)
                        : company.id,
                    folderId: folderNode.id,
                    moduleId: getModuleId(
                        product,
                        company
                            ? dropdownStateView
                                ? folderNode
                                : company
                            : {},
                        state
                    ),
                    pageNumber: "0",
                    productId: product?.productId.toString(),
                    returnId: currentScheduleId,
                    taxYearId: product?.taxYear.toString(),
                    quarterId: getQuarterId(quarter),
                };
                const accessToken = await getAccessTokenSilently();
                const {
                    scheduleTemplateDTO: { columnTemplateDTOs, rowDTOs },
                }: FixMeLater = await GQLService.fetchGraphQLData(
                    scheduleTemplateQuery,
                    { scheduleInput, queryJurisdiction: selectedJurisdiction },
                    accessToken
                );
                const updatedColumnMap = columnTemplateDTOs.reduce(
                    (map, column, index) => {
                        map.set(column.columnTemplate.name, index);
                        return map;
                    },
                    new Map<string, number>()
                );
                const canUseDisplayOrder = checkDisplayOrderInRowDTOs(rowDTOs);
                const updatedRowMap = rowDTOs.reduce(
                    (map, row: RowDTO, index) => {
                        map.set(
                            canUseDisplayOrder ? row.displayOrder : row.rowId,
                            index
                        );
                        return map;
                    },
                    new Map<string, number>()
                );
                setRowDTOs([...rowDTOs]);
                setColumnTemplateDTOs(columnTemplateDTOs);
                setRowMap(updatedRowMap);
                setColumnMap(updatedColumnMap);

                // display loading is required until fillDataGrid will be completed
                // need to stop loading only in case of empty rows received
                if (!rowDTOs?.length) {
                    setIsLoading(false);
                }
            } catch (error: FixMeLater) {
                console.error("Error loading the schedule:", error);
                setError("There has been an error loading the schedule.");
            }
        };
        if (!pendingChanges) {
            updatedRows.clear();
            setUpdatedRows(new Set([...updatedRows]));
            setPendingChanges(false);
            dispatch(GlobalStateActions.removePendingChange(documentKey));
            loadSBPData();
            if (!isSBPSchedule(returnNode)) {
                if (selectedJurisdiction === 0) {
                    fetchData();
                }
            } else {
                if (jurisdictions.get(selectedJurisdiction)) {
                    fetchData();
                }
            }
        }
    }, [
        folderNode,
        returnNode,
        selectedJurisdiction,
        selectedPage,
        toogleFetch,
    ]);

    // Handlers and other functions

    const handleSnackbar = (
        message: string | string[],
        severity: string,
        autoHideDuration: number = 3000
    ) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
        setSnackbarDuration(autoHideDuration);
    };

    const handleCloseMenu = () => setContextMenuPosition({ top: 0, left: 0 });

    const handleContextMenu = (event) => {
        event.preventDefault();
        setContextMenuPosition({
            top: event.clientY - 4,
            left: event.clientX - 2,
        });
    };

    const handleCellClick = useCallback(
        (params: GridCellParams) => {
            apiRef.current.setRowSelectionModel([]);
            if (params.field === columns[0].headerName) {
                apiRef.current.setRowSelectionModel([params.id]);
            }
        },
        [apiRef, rows, columns]
    );

    const onSaveClick = async () => {
        try {
            setIsLoading(true);
            setPendingChanges(false);
            dispatch(GlobalStateActions.removePendingChange(documentKey));
            dispatch(GlobalStateActions.setPendingChange(`${documentKey}*`));
            setTabLoadingMap(new Map([...tabLoadingMap, [documentKey, true]]));
            await updateSchedule().finally(() => {
                setIsLoading(false);
                setTabLoadingMap(
                    new Map([...tabLoadingMap, [documentKey, false]])
                );
                dispatch(
                    GlobalStateActions.removePendingChange(`${documentKey}*`)
                );
            });
            updatedRows.clear();
            setUpdatedRows(new Set([...updatedRows]));

            if (
                product?.productName === "Municipal" &&
                returnNode?.displayName.includes("Input Sch Qtr")
            ) {
                const treeInput = {
                    companyId: dropdownStateView
                        ? Number(folderNode?.id)
                        : company.id,
                    productId: product?.productId,
                    taxYearId: product?.taxYear,
                    moduleId: getModuleId(
                        product,
                        company
                            ? dropdownStateView
                                ? folderNode
                                : company
                            : {},
                        state
                    ),
                };

                const { tree } = await graphqlService.fetchData<{ tree: Tree }>(
                    returnTreeQuery,
                    { treeInput }
                );

                dispatch(
                    GlobalStateActions[product?.productName].setTree(tree)
                );

                handleSnackbar(
                    "Municipal returns have been automatically activated or updated.",
                    "success"
                );
            }

            // To update the other open return document or schedule if split screen is enabled
            // set the return or schedule just got updated to true
            // so that the other return document will be re-rendered and set it to false again
            if (splitScreen) {
                dispatch(GlobalStateActions.setToogleFetch(true));
            }
        } catch (e) {
            handleSnackbar(
                "There was an error when trying to save the Document.",
                "error"
            );
        }
    };

    const handlePageChange = (event: React.SyntheticEvent, page: number) => {
        if (pendingChanges) {
            const userConfirmed = window.confirm(
                "Changes you made may not be saved."
            );
            if (!userConfirmed) {
                return;
            }
        }
        setSelectedPage(page);
    };
    const handleResetRowsAfterSelectJurisdiction = () => {
        setUpdatedRows(new Set());
        setPendingChanges(false);
    };

    const handleSelectJurisdicion = (e: SelectChangeEvent<number>) => {
        if (pendingChanges) {
            const userConfirmed = window.confirm(
                "Changes you made may not be saved."
            );
            if (!userConfirmed) {
                return;
            }
        }
        handleResetRowsAfterSelectJurisdiction();
        setSelectedJurisdiction(Number(e.target.value));
        dispatch(
            GlobalStateActions.Premium.setSelectedJurisdictionForSBP(
                Number(e.target.value)
            )
        );
    };

    const handleDateChange = (
        newValue: Dayjs | null,
        rowId: number,
        columnName: string
    ) => {
        const rowIndex = rowMap.get(rowId);
        if (rowIndex != undefined) {
            const selectedRow = rowDTOs[rowIndex];
            const selectedColumnIndex = columnMap.get(columnName);
            if (selectedColumnIndex !== undefined) {
                const cell =
                    selectedRow.cellGroups[0].cells[selectedColumnIndex];
                cell.ov = newValue
                    ? newValue.format(cell.format?.toLocaleUpperCase())
                    : "";
                setRowDTOs([...rowDTOs]);
                updatedRows.add(rowIndex);
                setUpdatedRows(new Set([...updatedRows]));
                setPendingChanges(true);
                dispatch(GlobalStateActions.setPendingChange(documentKey));
            }
        }
    };

    const handleSimpleDropDownChange = (e: SelectChangeEvent, rowId: number, columnName: string) => {
        const rowIndex = rowMap.get(rowId);
        if (rowIndex != undefined) {
            const selectedRow = rowDTOs[rowIndex];
            const selectedColumnIndex = columnMap.get(columnName);
            if (selectedColumnIndex !== undefined) {
                const cell =
                    selectedRow.cellGroups[
                        returnNode.id !== addressScheduleId
                            ? 0
                            : selectedRow.currentIndex
                    ].cells[selectedColumnIndex];
                cell.ov = e.target.value
                setRowDTOs([...rowDTOs]);
                updatedRows.add(rowIndex);
                setUpdatedRows(new Set([...updatedRows]));
                setPendingChanges(true);
                dispatch(GlobalStateActions.setPendingChange(documentKey));
            }
        }
    }

    const handleRowDropDownChange = (e: SelectChangeEvent, rowId: number) => {
        const rowIndex = rowMap.get(rowId);
        if (rowIndex != undefined) {
            const selectedRow = rowDTOs[rowIndex];
            selectedRow.currentIndex = Number(e.target.value);
            setRowDTOs([...rowDTOs]);
            updatedRows.add(rowIndex);
            setUpdatedRows(new Set([...updatedRows]));
            setPendingChanges(true);
            dispatch(GlobalStateActions.setPendingChange(documentKey));
        }
    };

    const handleCheckboxChange = (rowId: number, columnName: string) => {
        const rowIndex = rowMap.get(rowId);
        if (rowIndex != undefined) {
            const selectedRow = rowDTOs[rowIndex];
            const selectedColumnIndex = columnMap.get(columnName);
            if (selectedColumnIndex !== undefined) {
                const cell =
                    selectedRow.cellGroups[
                        returnNode.id !== addressScheduleId
                            ? 0
                            : selectedRow.currentIndex
                    ].cells[selectedColumnIndex];
                const currentValue =
                    cell.ov !== undefined && cell.ov !== "" ? cell.ov : cell.v;
                cell.ov = (currentValue !== "true").toString();
                setRowDTOs([...rowDTOs]);
                updatedRows.add(rowIndex);
                setUpdatedRows(new Set([...updatedRows]));
                setPendingChanges(true);
                dispatch(GlobalStateActions.setPendingChange(documentKey));
            }
        }
    };

    const processRowUpdate = (newRow, oldRow) => {
        const updatedColumnNames = findUpdatedColumnNames(newRow, oldRow);
        if (updatedColumnNames.length > 0) {
            updatedColumnNames.forEach((updatedColumnName: string) => {
                const rowIndex = rowMap.get(newRow.id);
                const selectedColumnIndex = columnMap.get(updatedColumnName);
                if (selectedColumnIndex && rowIndex != undefined) {
                    const selectedRow = rowDTOs[rowIndex];
                    const cell =
                        selectedRow.cellGroups[
                            returnNode.id !== addressScheduleId
                                ? 0
                                : selectedRow.currentIndex
                        ].cells[selectedColumnIndex];

                    if (cell.cellType === CellType.NUMBER) {
                        let value = newRow[updatedColumnName];
                        // Remove commas from the value
                        value = value.toString().replace(/,/g, "");
                        if (isNaN(value)) {
                            setDialogTitle("Oops! Invalid Number Input");
                            setConfirmationMessage(
                                "This field only accepts numeric values. Please enter a valid number."
                            );
                            setShowConfirmation(true);
                            newRow[updatedColumnName] =
                                oldRow[updatedColumnName];
                        } else {
                            // Update the value without commas
                            newRow[updatedColumnName] = value;
                        }
                    }
                    if (!cell.editable) {
                        setDialogTitle("Oops! Field is Non-Editable");
                        setConfirmationMessage(
                            "This field is non-editable. Please paste data into an editable field."
                        );
                        setShowConfirmation(true);
                        newRow[updatedColumnName] = oldRow[updatedColumnName];
                    }

                    const value =
                        cell.cellType !== CellType.DATE
                            ? newRow[updatedColumnName]
                            : oldRow[updatedColumnName];
                    cell.ov = cell.v !== value ? value : "";
                    cell["hasPendingChanges"] = cell.ov !== "";
                    setRowDTOs([...rowDTOs]);
                    updatedRows.add(rowIndex);
                    setUpdatedRows(new Set([...updatedRows]));
                    setPendingChanges(true);
                    dispatch(GlobalStateActions.setPendingChange(documentKey));
                }
            });
        }
        return {
            ...newRow,
        };
    };

    const handleCloseConfirmation = () => {
        setShowConfirmation(false); // Close the dialog
    };

    const updateSchedule = async () => {
        const rowsToBeUpdated: RowDTO[] = Array.from(updatedRows).map(
            (index) => {
                const row = rowDTOs[index];
                return {
                    ...rowDTOs[index],
                    currentIndex:
                        returnNode.id !== addressScheduleId
                            ? 0
                            : row.currentIndex,
                };
            }
        );
        const currentScheduleId =
            returnNode.id === scheduleSBPIdLifePage1 && selectedPage === 1
                ? scheduleSBPIdLifePage2
                : returnNode.id;
        const scheduleInput: ScheduleRows = {
            queryJurisdiction: isSBPSchedule(returnNode)
                ? Number(selectedJurisdiction)
                : undefined,
            scheduleKey: {
                companyId: dropdownStateView
                    ? Number(folderNode?.id)
                    : company.id,
                moduleId: Number(
                    getModuleId(
                        product,
                        company
                            ? dropdownStateView
                                ? folderNode
                                : company
                            : {},
                        state
                    )
                ),
                productId: product?.productId,
                scheduleId: Number(currentScheduleId),
                taxYear: product?.taxYear,
                quarterId: getQuarterId(quarter),
            },
            rowDTOs: rowsToBeUpdated,
        };
        const result = await scheduleService.rowsUpdate(scheduleInput);
        const canUseDisplayOrder = checkDisplayOrderInRowDTOs(result.rowDTOs);
        result.rowDTOs.forEach((row) => {
            const rowIndex = rowMap.get(
                canUseDisplayOrder ? row.displayOrder : row.rowId
            );
            if (rowIndex != undefined) {
                rowDTOs[rowIndex] = row;
            }
        });
        setRowDTOs([...rowDTOs]);
    };

    const createColumnsWithGoButtonForLoginSchedule = (
        existingColumns: GridColDef[]
    ): GridColDef[] => {
        if (existingColumns.length === 0) {
            return existingColumns;
        }
        const lastColumn = { ...existingColumns[existingColumns.length - 1] };

        lastColumn.field = "go";
        lastColumn.headerName = "Go";
        lastColumn.width = 20;
        lastColumn.sortable = false;
        lastColumn.editable = false;
        lastColumn.renderCell = (params) => {
            return (
                <IconButton
                    aria-label="go"
                    onClick={() => {
                        if (pendingChanges) {
                            handleSnackbar(
                                "Please save changes before logging in",
                                "warning"
                            );
                            return;
                        }
                        openLogin(params.row, returnNode, handleSnackbar);
                    }}
                >
                    <LaunchIcon />
                </IconButton>
            );
        };

        return [...existingColumns, lastColumn];
    };

    const handleCellKeyDown = (
        params: GridCellParams,
        event: React.KeyboardEvent
    ) => {
        if (!apiRef.current) return;

        const key = event.key;
        const currentRowId = params.id;

        const currentRowIndex = rows.findIndex(
            (row) => row.id === currentRowId
        );
        const currentColumnIndex = columns.findIndex(
            (col) => col.field === params.field
        );

        if (key === "Tab") {
            event.preventDefault();

            if (
                apiRef.current.getCellMode(params.id, params.field) === "edit"
            ) {
                apiRef.current.stopCellEditMode({
                    id: params.id,
                    field: params.field,
                    ignoreModifications: false,
                });
            }

            let newRowIndex = currentRowIndex;
            let newColumnIndex = currentColumnIndex;

            if (event.shiftKey) {
                newColumnIndex -= 1;
                if (newColumnIndex < 0) {
                    newRowIndex -= 1;
                    newColumnIndex = columns.length - 1;
                }
            } else {
                newColumnIndex += 1;
                if (newColumnIndex >= columns.length) {
                    newRowIndex += 1;
                    newColumnIndex = 0;
                }
            }

            if (newRowIndex < 0) {
                newRowIndex = 0;
                newColumnIndex = 0;
            } else if (newRowIndex >= rows.length) {
                newRowIndex = rows.length - 1;
                newColumnIndex = columns.length - 1;
            }

            const newRowId = rows[newRowIndex].id;
            const newColumnField = columns[newColumnIndex].field;

            apiRef.current.setCellFocus(newRowId, newColumnField);
            setCellSelected({ [newRowId]: { [newColumnField]: true } });

            apiRef.current.scrollToIndexes({
                rowIndex: newRowIndex,
                colIndex: newColumnIndex,
            });

            return;
        }

        if (key === "ArrowLeft" && currentColumnIndex === 0) {
            if (currentRowIndex > 0) {
                const newRowId = rows[currentRowIndex - 1].id;
                const newColumnField = columns[columns.length - 1].field;
                apiRef.current.setCellFocus(newRowId, newColumnField);
                setCellSelected({ [newRowId]: { [newColumnField]: true } });

                apiRef.current.scrollToIndexes({
                    rowIndex: currentRowIndex - 1,
                    colIndex: columns.length - 1,
                });
            }
        } else if (
            key === "ArrowRight" &&
            currentColumnIndex === columns.length - 1
        ) {
            if (currentRowIndex < rows.length - 1 && columns.length > 1) {
                const newRowId = rows[currentRowIndex + 1].id;
                const newColumnField = columns[0].field;
                apiRef.current.setCellFocus(newRowId, newColumnField);
                setCellSelected({ [newRowId]: { [newColumnField]: true } });

                apiRef.current.scrollToIndexes({
                    rowIndex: currentRowIndex + 1,
                    colIndex: 0,
                });
            }
        }
    };

    // Render Logic
    const contextMenuItems: ContextMenuItem[] = [
        {
            label: "Copy",
            onClick: () =>
                handleCopy(
                    rowSelected,
                    rowDTOs,
                    returnNode,
                    cellSelected,
                    apiRef,
                    handleCloseMenu
                ),
        },
        {
            label: "Copy with Headers",
            onClick: () =>
                handleCopyWithHeaders(
                    rowSelected,
                    columns,
                    rowDTOs,
                    returnNode,
                    cellSelected,
                    apiRef,
                    handleCloseMenu
                ),
        },
        {
            label: "Paste",
            onClick: () =>
                handlePaste(
                    setIsPasteAvailable,
                    handleSnackbar,
                    handleCloseMenu,
                    rows,
                    columns,
                    cellSelected,
                    processRowUpdate
                ),
            disabled: !isPasteAvailable,
        },
    ];

    if (error) return <ErrorMessage error={error.toString()} />;
    if (!error && isLoading)
        return (
            <>
                <Loader />
                <CustomSnackbar
                    open={snackbarOpen}
                    setOpen={setSnackbarOpen}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                    autoHideDuration={snackbarDuration}
                />
            </>
        );

    return (
        <div className="data-table-container" onContextMenu={handleContextMenu}>
            <div className="grid-container">
                <StyledDataGrid
                    hideFooter
                    density="compact"
                    processRowUpdate={processRowUpdate}
                    columns={columns}
                    rows={rows}
                    slots={{
                        toolbar: () =>
                            getCustomToolbar(
                                returnNode,
                                handlePageChange,
                                selectedPage,
                                jurisdictions,
                                selectedJurisdiction,
                                handleSelectJurisdicion
                            ),
                        noRowsOverlay: customNoData,
                        columnMenuIcon: () => <></>,
                        columnMenuFilterIcon: () => <FilterAltOutlinedIcon />,
                        columnMenu: (props) => CustomColumnMenu(props, apiRef),
                    }}
                    sortModel={sortModel}
                    columnHeaderHeight={headerHeight}
                    isCellEditable={(params) => {
                        return (
                            !tabsProperties[documentKey]?.isLocked &&
                            isCellEditable(params)
                        );
                    }}
                    onCellClick={handleCellClick}
                    onCellSelectionModelChange={(newCellSelectionModel) => {
                        setCellSelected(newCellSelectionModel);
                    }}
                    disableRowSelectionOnClick
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setRowSelected(newRowSelectionModel);
                    }}
                    ignoreValueFormatterDuringExport
                    apiRef={apiRef}
                    groupingColDef={{
                        renderHeader: (params) =>
                            customColumnHeader(
                                params,
                                true,
                                apiRef,
                                sortModel,
                                setSortModel
                            ),
                    }}
                    cellSelection
                    cellSelectionModel={cellSelected}
                    disableMultipleRowSelection
                    rowSelection={false}
                    onBeforeClipboardPasteStart={async () => {
                        handlePaste(
                            setIsPasteAvailable,
                            handleSnackbar,
                            handleCloseMenu,
                            rows,
                            columns,
                            cellSelected,
                            processRowUpdate
                        );
                        throw new Error("Paste handled"); // This will prevent the default paste behavior from Mui
                    }}
                    onCellKeyDown={handleCellKeyDown}
                />
                <SelectAllRowsButton returnNode={returnNode} apiRef={apiRef} />
            </div>
            {showMenu && (
                <DataTableContextMenu
                    open={isMenuOpen}
                    position={contextMenuPosition}
                    items={contextMenuItems}
                    onClose={handleCloseMenu}
                />
            )}
            <div className="grid-right-toolbar-container">
                <Toolbar
                    items={getToolbarIcons(
                        apiRef,
                        documentKey,
                        handleSnackbar,
                        onSaveClick,
                        pendingChanges
                    )}
                    itemSelected={selectedItemName}
                    onItemClicked={() => {}}
                />
                {/* <Toolbar items={iconData} /> */}
            </div>
            <CustomSnackbar
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                message={snackbarMessage}
                severity={snackbarSeverity}
                autoHideDuration={snackbarDuration}
            />
            <ConfirmationDialog
                open={showConfirmation}
                title={dialogTitle}
                message={confirmationMessage}
                onClose={handleCloseConfirmation}
            />
        </div>
    );
};

export default DataTable;
